var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-content-box',{attrs:{"is-loading-process":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Service accounts"},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-blacker-button',{attrs:{"small":"","color":"info","to":{ name: 'accountCreator' }}},[_vm._v(" Add account ")])]},proxy:true},{key:"upper",fn:function(){return [_c('hr',{staticClass:"vbt-hr-divider"}),_c('v-blacker-layout',[_c('v-blacker-flex',{attrs:{"lg3":""}},[_c('v-blacker-input',{attrs:{"label":"Filter by substring","clearable":""},model:{value:(_vm.accountFilter),callback:function ($$v) {_vm.accountFilter=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"accountFilter"}})],1),_c('v-blacker-spacer'),_c('v-blacker-flex',{attrs:{"lg2":""}},[_c('v-blacker-layout',{attrs:{"align-center":""}},[_c('v-blacker-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState}})],1)],1)],1)]},proxy:true}])},[_c('v-blacker-table',{attrs:{"headers":_vm.accountsListHeaders,"items":_vm.accounts},scopedSlots:_vm._u([{key:"accountType",fn:function(ref){
var accountType = ref.item.accountType;
return [_vm._v(" "+_vm._s(_vm.accountTypesByTypeId[accountType])+" ")]}},{key:"xpress",fn:function(ref){
var xpressEnabled = ref.item.xpressEnabled;
return [(xpressEnabled)?_c('vbt-badge',{attrs:{"color":"success","small":""}},[_vm._v(" Xpress ")]):_vm._e()]}},{key:"actions",fn:function(ref){
var ref_item = ref.item;
var accountId = ref_item.accountId;
var disabled = ref_item.disabled;
var accountFullName = ref_item.accountFullName;
return [_c('v-blacker-layout',[_c('v-blacker-button',{staticClass:"vbt-margin-r-5",attrs:{"to":{
            name: 'account',
            params: { id: accountId },
          },"icon":"","icon-name":"edit","color":"warning"}}),_c('v-blacker-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-blacker-button',_vm._g({attrs:{"icon-name":disabled ? 'toggle_on' : 'toggle_off',"color":disabled ? 'error' : 'success',"icon":""},on:{"click":function($event){return _vm.changeAccountState(accountId, accountFullName, disabled)}}},on))]}}],null,true)},[_vm._v(" "+_vm._s(((disabled ? 'Enable' : 'Disable') + " account"))+" ")])],1)]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount,"total-count":_vm.accountsTotalCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useDataLoading } from '@mixins/factories';

import { generateXHRError, useThrottle } from '@helpers';

const dataLoadingOptions = { getterName: 'getAccounts', searchable: false };
const paginationOptions = { itemsName: 'accounts' };

const accountsListHeaders = Object.freeze([
  { text: 'Name', value: 'accountName' },
  { text: 'Full Name', value: 'accountFullName' },
  { text: 'Role', value: 'accountType' },
  { text: '', value: 'xpress', width: '7%' },
  { text: '', value: 'actions', width: '5%' },
]);

export default {
  name: 'Accounts',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
  ],
  data() {
    return {
      activeOnly: true,
      accountFilter: '',
      accountsListHeaders,
      goBack: {
        text: 'Back to previous',
        callback: this.goBackAction,
      },
    };
  },
  computed: {
    ...mapState('accounts', ['accounts', 'accountsTotalCount']),
    ...mapGetters('enums', ['accountTypesByTypeId']),
    throttledRequest() {
      const DELAY = 300;
      return useThrottle(this.onAccountNameFilterChange, DELAY);
    },
  },
  watch: {
    accountFilter() {
      this.throttledRequest();
    },
  },
  methods: {
    ...mapActions('accounts', ['getAccounts']),
    onAccountNameFilterChange() {
      this.pageNum = '1';
      this.allowGettingData = true;
    },
    changeAccountsState(value) {
      this.activeOnly = !value;
      this.allowGettingData = true;
    },
    changeAccountState(id, name, disabled) {
      this.$VBlackerTheme.alert.warning({
        text: `${disabled ? 'Enable' : 'Disable'} ${name}?`,
      }, async () => {
        this.$VBlackerTheme.openLoadingSpinner();
        try {
          await this.$http.post(`accounts/${id}/state`, { disabled: !disabled });
          this.$VBlackerTheme.notification.success('Account state successfully changed');
          this.allowGettingData = true;
        } catch (e) {
          this.$VBlackerTheme.notification.error(generateXHRError(e));
        } finally {
          this.$VBlackerTheme.closeLoadingSpinner();
        }
      });
    },
    async getData() {
      this.isLoading = true;
      this.loadingError.show = false;

      try {
        await this.getAccounts({
          pageSize: 10,
          activeOnly: this.activeOnly,
          nameToken: this.accountFilter || null,
          pageNum: this.pageNum || '1',
        });
        return Promise.resolve();
      } catch (e) {
        Object.assign(this, {
          loadingError: { show: true, text: e },
        });
        return Promise.reject();
      } finally {
        this.isLoading = false;
      }
    },
    goBackAction() {
      if (Number(this.pageNum) === 1) {
        this.accountFilter = '';
      } else {
        this.$router.go(-1);
      }
    },
  },
};
